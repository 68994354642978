import { DataAccessService } from "../core/api";

export default async function getAllowedOrgs() {
  try {
    const client = new DataAccessService();
    const response = await client.get(`/api/orgs`);
    return response;
  } catch (err) {
    console.warn(err);
    throw err;
  }
}
